.research-section {
  padding: 2em 0;
  background-color: var(--clr-bg);
}

.research-section.light {
  --card-bg: var(--clr-bg);
  --card-border: var(--clr-border);
  --card-shadow: var(--clr-shadow);
  --text-primary: var(--clr-fg);
  --text-secondary: var(--clr-fg-alt);
}

.research-section.dark {
  --card-bg: var(--clr-bg);
  --card-border: var(--clr-border);
  --card-shadow: var(--clr-shadow);
  --text-primary: var(--clr-fg);
  --text-secondary: var(--clr-fg-alt);
}

.research-section h1 {
  text-align: center;
  width: 100%;
  margin-bottom: 2rem;
  font-size: 2.5rem;
  font-weight: bold;
}

.research-card {
  transition: transform 0.3s ease-in-out;
  border: 1px solid var(--clr-border);
  background-color: var(--clr-bg-alt);
  overflow: hidden;
}

.research-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.research-image-container {
  overflow: hidden;
  height: 100%;
}

.research-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.research-title {
  color: var(--clr-primary);
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.research-highlights {
  list-style-type: none;
  padding-left: 0;
  margin: 1.5rem 0;
}

.research-highlights li {
  position: relative;
  padding-left: 1.5rem;
  margin-bottom: 0.5rem;
}

.research-highlights li::before {
  content: "•";
  position: absolute;
  left: 0;
  color: var(--clr-primary);
}

.research-links {
  margin-top: 1.5rem;
}

.research-links .btn {
  margin-right: 1rem;
}

@media (max-width: 768px) {
  .research-image-container {
    height: 200px;
  }
}

/* 可选：添加卡片悬停效果 */
.MuiCard-root {
  transition: transform 0.2s ease-in-out;
}

.MuiCard-root:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

/* 响应式调整 */
@media (max-width: 768px) {
  .research-section {
    margin-top: 5em;
  }
  
  .research-section h1 {
    font-size: 2rem;
  }
}
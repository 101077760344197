.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 5em; /* 使用了.section的margin-top值 */
  max-width: 1100px;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  gap: 2em;
}

.contact_info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.info_item h4 {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px 0;
  color: var(--clr-fg); /* 使用主题前景色 */
}

.info_item a {
  color: var(--clr-primary); /* 使用主题主色 */
  text-decoration: none;
}

.info_item a:hover {
  text-decoration: underline;
  text-decoration-color: var(--clr-primary); /* 使用主题主色 */
}

/* 调整图标大小和颜色 */
.info_item svg {
  font-size: 24px;
  color: var(--clr-primary); /* 使用主题主色 */
}

/* 如果您希望链接按钮与其他按钮风格一致 */
.contact_info .btn--outline {
  color: var(--clr-primary);
  border: 2px solid var(--clr-primary);
  transition: all 0.3s ease;
}

.contact_info .btn--outline:hover {
  color: var(--clr-bg); /* 鼠标悬浮时文字颜色变为背景色 */
  background-color: var(--clr-primary); /* 鼠标悬浮时背景颜色变为主色 */
}

/* 响应式布局调整 */
@media (max-width: 600px) {
  .contact {
    margin-top: 4em;
  }
}
.map {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 5em; /* 使用了.section的margin-top值 */
  max-width: 1100px;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  gap: 2em;
}

.map_info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.info_item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  color: var(--clr-fg); /* 使用主题前景色 */
}

#map-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 500px;
}

/* 响应式布局调整 */
@media (max-width: 600px) {
  .map {
    margin-top: 4em;
  }
  .info_item img {
    width: 100%; /* 在小屏幕上设置宽度为100% */
    max-width: none; /* 取消最大宽度限制 */
  }
}

.section.map {
  padding: 4rem 0;
  background-color: var(--bg-color);
}

#map-container {
  position: relative;
  overflow: hidden;
}

/* 可选：添加加载动画 */
#map-container::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  opacity: 0;
  transition: opacity 0.3s;
}

#map-container:empty::before {
  opacity: 1;
}

@keyframes spin {
  0% { transform: translate(-50%, -50%) rotate(0deg); }
  100% { transform: translate(-50%, -50%) rotate(360deg); }
}
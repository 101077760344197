.footer {
  padding: 1em 0;
  text-align: center;
}

.footer__link {
  font-size: 0.8rem; /* 设置较小的字体 */
  font-weight: 400; /* 设置正常的字体粗细 */
  color: var(--clr-fg);
  text-decoration: none; /* 移除下划线 */
}
.intro {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 10em;
  flex-wrap: nowrap; /* 确保内容不会换行 */
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  gap: 2em; /* 可选：如果您的目标浏览器支持在flex布局中使用gap */
}

.intro__card, .intro__text {
  flex: 1; /* 使两个主要部分各占一半宽度 */
  max-width: 50%; /* 限制最大宽度为容器的一半 */
}

/* 增加间隙 */
.intro__card {
  position: relative;
  margin-right: 2em;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* 确保内容均匀分布 */
  height: 100%; /* 新增：确保卡片高度一致 */
  box-sizing: border-box; /* 新增：确保边框和内边距包含在宽度和高度内 */
  transition: 0.3s;
  border-radius: 5px; /* Optional */
}
/* 为description中的所有<a>标签添加特定样式 */
.intro__card p a {
  color: #0072ec; /* 超链接颜色 */
  text-decoration: none; /* 移除默认下划线 */
  position: relative;
}


.intro__card img {
  width: 100%;
  height: auto; /* 调整为auto，确保图片比例不变 */
  border-radius: 5px 5px 0 0; /* Optional */
  height: auto;
  border-radius: 5px 5px 0 0;
}
.intro__text {
  padding: 20px;
  max-width: 1000px; /* 增加文本容器的最大宽度 */
  margin: 0 auto; /* 居中对齐 */
}

/* 增加段落之间的间距 */
.intro__text p {
  margin-bottom: 2.5em;
}

/* 为intro__text中的所有<a>标签添加特定样式 */
.intro__text a {
  color: #0072ec; /* 超链接颜色 */
  text-decoration: none; /* 移除默认下划线 */
  position: relative;
}



.intro__text a:hover {
  color: #005bb5; /* 悬停时的颜色 */
}

/* 新增按钮样式，确保按钮位于文字下方且不太大 */
.intro__resume-button {
  justify-content: center;
  text-align: center;
  max-width: 200px; /* 限制按钮的最大宽度 */
  width: 60%; /* 使按钮宽度适应内容 */
  margin: 2em auto 0; /* 上边距保持2em，左右自动（实现居中），下边距为0 */
}

.btn--outline {
  border: 2px solid var(--clr-primary);
  color: var(--clr-primary);
  background-color: transparent;
  padding: 0.5em 1.5em;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.btn--outline:hover,
.btn--outline:focus {
  background-color: var(--clr-primary);
  color: var(--clr-bg);
}

.keyword {
  position: relative;
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: lightblue;
}

.keyword:hover::after, .keyword.highlighted::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: rgb(218, 174, 15);
  bottom: -1px;
  left: 0;
}

.keyword::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: transparent;
  bottom: -3px;
  left: 0;
}
.intro__arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 10; /* 确保箭头位于其他内容之上 */
}

.intro__arrow--left {
  left: -25px; /* 根据需要调整，确保箭头位于卡片左边缘 */
}

.intro__arrow--right {
  right: -25px; /* 根据需要调整，确保箭头位于卡片右边缘 */
}